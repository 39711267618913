var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoaded)?_c('div',[_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-body py-8 px-8 py-lg-20 px-lg-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"offset-xxl-2 col-xxl-8"},[_c('h4',{staticClass:"mb-10 font-weight-bold text-dark"},[_vm._v(" Bestellungen ")]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-head-custom table-vertical-center table-head-bg table-borderless"},[_vm._m(0),_c('tbody',[_vm._l((_vm.subscriptionOrders),function(subscription_order,n){return [_vm._l((subscription_order.subscription_options),function(subscription_option,i){return [_c('tr',{key:'O' + i},[_vm._m(1,true),_c('td',[_c('span',{staticClass:"text-dark-75 d-block font-size-lg"},[_vm._v(_vm._s(_vm.formatDateTime(subscription_option.added_date)))])]),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"text-dark-75 d-block font-size-lg"},[_vm._v("CHF "+_vm._s(_vm.formatFloat( subscription_option.invoice.invoice_amount )))])]),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"label label-lg label-inline",class:("label-light-" + (_vm.getInvoiceStatusType(
                              subscription_option.invoice.invoice_status
                            )))},[_vm._v(" "+_vm._s(subscription_option.invoice.invoice_status_display)+" ")])]),_c('td',{staticClass:"pr-0 text-right"},[_c('router-link',{attrs:{"to":{
                            name: 'subscription-options',
                            params: {
                              orderId: subscription_order.id,
                              optionsId: subscription_option.id
                            }
                          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var href = ref.href;
                          var navigate = ref.navigate;
                          var isActive = ref.isActive;
                          var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"btn btn-light-success font-weight-bolder font-size-sm",on:{"click":navigate}},[_vm._v("Details")])]}}],null,true)})],1)])]}),_c('tr',{key:'S' + n},[_vm._m(2,true),_c('td',[_c('span',{staticClass:"text-dark-75 d-block font-size-lg"},[_vm._v(_vm._s(_vm.formatDateTime(subscription_order.start_date)))])]),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"text-dark-75 d-block font-size-lg"},[_vm._v("CHF "+_vm._s(_vm.formatFloat( subscription_order.invoice.invoice_amount )))])]),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"label label-lg label-inline",class:("label-light-" + (_vm.getInvoiceStatusType(
                            subscription_order.invoice.invoice_status
                          )))},[_vm._v(" "+_vm._s(subscription_order.invoice.invoice_status_display)+" ")])]),_c('td',{staticClass:"pr-0 text-right"},[_c('router-link',{attrs:{"to":{
                          name: 'subscription-order',
                          params: {
                            orderId: subscription_order.id
                          }
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var href = ref.href;
                        var navigate = ref.navigate;
                        var isActive = ref.isActive;
                        var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"btn btn-light-success font-weight-bolder font-size-sm",on:{"click":navigate}},[_vm._v("Details")])]}}],null,true)})],1)])]})],2)])])])])])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"text-left"},[_c('th',{staticClass:"pl-7",staticStyle:{"min-width":"250px"}},[_c('span',{staticClass:"text-dark-75"},[_vm._v("Produkt")])]),_c('th',{staticStyle:{"min-width":"120px"}},[_vm._v("Datum")]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"100px"}},[_vm._v("Preis")]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"100px"}},[_vm._v("Status")]),_c('th',{staticStyle:{"min-width":"101px"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"py-5"},[_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v("Optionen hinzugefügt")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"py-5"},[_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v("Basisabonement")])])}]

export { render, staticRenderFns }